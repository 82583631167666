import { createAction, createReducer } from "@reduxjs/toolkit";
import type { GradeSelectorLabelType } from "../../utils/labels";
import { gradeSelectorLabels } from "../../utils/labels";

export type GradeSelectorSettingsType = {
    initialized: boolean;
    labels: Record<GradeSelectorLabelType, string>;
    showETA: boolean;
    etaMatchingType: string; // Determines which cars are returned for ETA, see https://t1-preview-wiki.toyota-europe.com/index.php/CarConfig_Settings#summaryEtaMatchingType
};

export const initialStateGradeSelectorSettings: GradeSelectorSettingsType = {
    initialized: false,
    labels: { ...gradeSelectorLabels },
    showETA: false,
    etaMatchingType: "",
};

export const initGradeSelectorSettings = createAction<GradeSelectorSettingsType, "gradeSelectorSettings/init">(
    "gradeSelectorSettings/init",
);

export type GradeSelectorSettingsActionTypes = ReturnType<typeof initGradeSelectorSettings>;

const GradeSelectorSettingsReducer = createReducer(initialStateGradeSelectorSettings, (builder) => {
    builder.addCase(initGradeSelectorSettings, (state, action) => {
        return {
            ...state,
            ...action.payload,
            initialized: true,
        };
    });
});

export default GradeSelectorSettingsReducer;
