import React, { useCallback, useEffect } from "react";
import loadable from "@loadable/component";
import type { ModalViewType } from "../../../../../../../../common-deprecated/types/CommonTypes";
import type { GradeSelectorCompareModalType } from "../../../../../utils/modalConstants";
import { useGradeSelectorSelector } from "../../../../../redux/hooks";
import SidePanel from "../../../../../../shared-components/css-modules/toyota/organisms/SidePanel";
import { selectModel } from "../../../../../redux/slices/GradeSelectorModelSlice";
import { trackCloseCompare, trackOpenCompare } from "../../../../../utils/tracking";
import { useDrag } from "../../../../../hooks/useDrag";
import styles from "../../../../../../shared-components/css-modules/toyota/organisms/SidePanel.module.scss";

const IntegratedCrossModelCompare = loadable(async () => {
    const component = await import(
        /* webpackChunkName: "integrated-cross-model-compare-toyota" */
        "../../../../../../cross-model-compare/components/toyota/IntegratedCrossModelCompare"
    );
    return component.default;
});

const GradeSelectorCompareModal = (props: ModalViewType<GradeSelectorCompareModalType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { models } = modalSettings;
    const { gradeId, submodelId, colourId, motorization } = models[0];
    const { id } = useGradeSelectorSelector(selectModel);

    useEffect(() => {
        trackOpenCompare();
    }, [show]);

    const onRequestClose = useCallback(() => {
        trackCloseCompare();
        close();

        // Should only run once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { handleMouseDown, handleMouseUp, handleMouseMove, ref } = useDrag<HTMLDialogElement>();

    return (
        <SidePanel
            ref={ref}
            variant="fullscreen"
            open={show}
            onRequestClose={onRequestClose}
            fromDirection="block-end"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className={styles["cross-compare"]}
        >
            <IntegratedCrossModelCompare
                modelId={id}
                gradeId={gradeId}
                submodelId={submodelId}
                colourId={colourId}
                motorization={motorization}
            />
        </SidePanel>
    );
};

export default GradeSelectorCompareModal;
