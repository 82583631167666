import type { PropsWithChildren } from "react";
import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import styles from "./Modal.module.scss";
import Button from "../atoms/Button";
import Icon from "../atoms/Icon";
import type { IconVariantTypes } from "../atoms/Icon.types";
import { useCloseOnOutsideDialog } from "../../../../../../shared-logic/hooks/useCloseOnOutsideDialog";

export type ModalProps = PropsWithChildren<{
    variant?:
        | "default"
        | "debug"
        | "fullscreen"
        | "fullscreen-spin"
        | "calculator"
        | "lcv-filter"
        | "filter"
        | "bottom";
    onRequestClose?: () => void;
    // Adds a title CTA on the top left of the modal that has an optional icon and a custom onClick function.
    headerAction?: { label: string; onClick: () => void; icon?: IconVariantTypes };
}> &
    React.HTMLProps<HTMLDialogElement>;

const Modal: React.FC<ModalProps> = ({
    open,
    variant = "default",
    children,
    onRequestClose,
    headerAction,
    className,
    ...rest
}) => {
    const ref = useRef<HTMLDialogElement | null>(null);

    const dialogClasses = useMemo(() => {
        return clsx(styles.modal, styles[variant], className);
    }, [open, variant]);

    useEffect(() => {
        if (open && !ref.current?.open) {
            ref.current?.showModal();
        } else if (!open && ref.current?.open) {
            ref.current?.close();
        }
    }, [open]);

    const closeModal = useCallback(() => {
        onRequestClose?.();
        ref.current?.close();
    }, [onRequestClose]);

    useCloseOnOutsideDialog(ref, closeModal, !open);

    return (
        <>
            <dialog ref={ref} {...rest} onCancel={closeModal} className={dialogClasses}>
                <div>
                    <div className={styles.header}>
                        {headerAction && (
                            <Button className={styles["header-action"]} onClick={headerAction.onClick} variant="clean">
                                {headerAction.icon && (
                                    <Icon className={styles.icon} sizing="sm" variant={headerAction.icon} />
                                )}
                                {headerAction.label}
                            </Button>
                        )}

                        <Button
                            variant="btn-default"
                            circle
                            onClick={closeModal}
                            aria-label="close"
                            className={styles.close}
                        >
                            <Icon variant="close-sm" sizing="xxs" aria-hidden="true" />
                        </Button>
                    </div>
                    {children}
                </div>
            </dialog>
        </>
    );
};

export default memo(Modal);
