import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { GradeBodySelectedMotorizationType, ModelType } from "../../utils/types/Model";
import type { GradeSelectorStateType } from "../store";
import { getUniqueGradeBodyTypeId } from "../../../../../common-deprecated/features/apheleia/utils/aphUtils";

export type GradeSelectorModelType = {
    initialized: boolean;
    model: ModelType;
};

export const gradeSelectorModelSlice = createSlice({
    name: "gradeSelectorModel",
    initialState: {
        initialized: false,
    } as GradeSelectorModelType,
    reducers: {
        init(state, action: PayloadAction<ModelType>) {
            state.model = action.payload;
            state.initialized = true;
        },
        setSelectedMotorization(
            state,
            action: PayloadAction<{
                uniqueBodyTypeId: string;
                motorization: GradeBodySelectedMotorizationType;
            }>,
        ) {
            const { uniqueBodyTypeId, motorization } = action.payload;

            const selectedGradeBodyType = state.model.gradeBodyTypes.find((gradeBodyType) => {
                return (
                    uniqueBodyTypeId ===
                    getUniqueGradeBodyTypeId(
                        gradeBodyType.grade.id,
                        gradeBodyType.bodyType.id,
                        gradeBodyType.submodel?.id,
                    )
                );
            });

            if (selectedGradeBodyType) selectedGradeBodyType.selectedMotorization = motorization;
        },
        setColour(state, action: PayloadAction<{ uniqueBodyTypeId: string; colourId: string }>) {
            const { uniqueBodyTypeId, colourId } = action.payload;
            const selectedGradeBodyType = state.model.gradeBodyTypes.find((gradeBodyType) => {
                return (
                    uniqueBodyTypeId ===
                    getUniqueGradeBodyTypeId(
                        gradeBodyType.grade.id,
                        gradeBodyType.bodyType.id,
                        gradeBodyType.submodel?.id,
                    )
                );
            });

            if (selectedGradeBodyType) selectedGradeBodyType.selectedColourId = colourId;
        },
    },
});

export const { init, setColour, setSelectedMotorization } = gradeSelectorModelSlice.actions;

export const selectModel = (state: GradeSelectorStateType): ModelType => state.gradeSelectorModel.model;
