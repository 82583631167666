import type { ModalMapType } from "../../../../../../common-deprecated/types/CommonTypes";
import type { GradeSelectorModalIdsType } from "../../../utils/modalConstants";
import {
    MODAL_DEBUG_FINANCE,
    MODAL_DEBUG_LCV_FILTERS,
    MODAL_GRADE_SELECTOR_COMPARE,
    MODAL_MOBILE_GRADE_SELECTOR_FILTER,
    MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER,
} from "../../../utils/modalConstants";
import FinanceModal from "../../../shared-components/debug-modals/FinanceModal";
import GradeSelectorCompare from "../selector/actions/compare/GradeSelectorCompare";
import { aemToyotaDebugModalMap } from "../../../../../../common-deprecated/components/modals/aem/modals";
import MobileFilterModal from "./filters/MobileFilterModal";
import MobileLcvFilterModal from "./filters/MobileLcvFilterModal";
import { LcvFiltersDebugModal } from "./debug-modals/LcvFiltersModal";
import ErrorModal from "../../../../shared-components/toyota/debug-modals/ErrorModal";
import { MODAL_ERRORS } from "../../../../../../common-deprecated/utils/modalConstants";

export const aemGradeSelectorToyotaModalMap: ModalMapType<GradeSelectorModalIdsType> = {
    ...aemToyotaDebugModalMap,
    [MODAL_DEBUG_FINANCE]: FinanceModal,
    [MODAL_DEBUG_LCV_FILTERS]: LcvFiltersDebugModal,
    [MODAL_GRADE_SELECTOR_COMPARE]: GradeSelectorCompare,
    [MODAL_MOBILE_GRADE_SELECTOR_FILTER]: MobileFilterModal,
    [MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER]: MobileLcvFilterModal,
    [MODAL_ERRORS]: ErrorModal,
};
