import type { GradeSelectorModalIdsType } from "../utils/modalConstants";
import { MODAL_GRADE_SELECTOR_COMPARE } from "../utils/modalConstants";
import { showModal } from "../../../../common-deprecated/redux/actions/ModalActions";
import type { GradeSelectorDispatchType } from "../redux/store";
import { useGradeSelectorSelector } from "../redux/hooks";
import { MODAL_ERRORS } from "../../../../common-deprecated/utils/modalConstants";
import { useDispatch } from "../../../../shared-logic/redux/store";

type useGradeSelectorModalType = {
    showModalHelper: (type: GradeSelectorModalIdsType) => void;
};

/**
 * This hook is responsible for using the correct showModal dispatcher.
 *
 * @returns {showModalHelper} showModalHelper function which has to be called in order to show a modal, requires the modals' type.
 */
const useGradeSelectorModal = (): useGradeSelectorModalType => {
    const dispatch = useDispatch<GradeSelectorDispatchType>();
    const { modals } = useGradeSelectorSelector((state) => state.modal);

    const showModalHelper = (type: GradeSelectorModalIdsType): void => {
        // Only show modal if the modal isn't already displayed.
        if (modals.length === 0 || modals.find((modal) => modal.settings?.type === type) === undefined) {
            switch (type) {
                // Error modal should not be triggered by anything other than the commonPostfetch
                case MODAL_ERRORS: {
                    break;
                }
                case MODAL_GRADE_SELECTOR_COMPARE: {
                    break;
                }
                default: {
                    dispatch(
                        showModal({
                            type,
                        }),
                    );
                    break;
                }
            }
        }
    };

    return { showModalHelper };
};

export default useGradeSelectorModal;
