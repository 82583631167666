import type { FC } from "react";
import React from "react";
import { usePromotions } from "../../../../utils/disclaimerHooks";
import styles from "./Disclaimers.module.scss";

const Disclaimers: FC = () => {
    const promotions = usePromotions();

    if (!promotions || promotions.length === 0) {
        return null;
    }

    return (
        <section className={styles.disclaimers}>
            {promotions.map(({ id, index, disclaimer, name }) => (
                <div className={styles.disclaimer} key={id} id={`disclaimer-${id}`}>
                    <div className={styles["disclaimer-number"]}>{index}</div>
                    {name && <div className={styles["disclaimer-title"]}>{name}</div>}
                    <div dangerouslySetInnerHTML={{ __html: disclaimer as string }} />
                </div>
            ))}
        </section>
    );
};

export default Disclaimers;
