import { FloatingFocusManager, FloatingPortal, useMergeRefs, useTransitionStyles } from "@floating-ui/react";
import type { FC, HTMLProps, ReactNode } from "react";
import React, { cloneElement, forwardRef, Fragment, isValidElement, useCallback } from "react";

import Floater from "../Floater";

import type { PopoverOptions } from "./hooks";
import { PopoverContext, usePopover, usePopoverContext } from "./hooks";

const PopoverTrigger = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(
    ({ children, ...props }, propRef) => {
        const context = usePopoverContext();

        // Disabled rule because children of context could actually be any
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        const childrenRef = (children as any).ref;

        const ref = useMergeRefs([context?.refs.setReference, propRef, childrenRef]);
        if (!context) return null;
        if (isValidElement(children)) {
            return cloneElement(
                children,
                context.getReferenceProps({
                    ref,
                    ...props,
                    ...children.props,
                    "data-state": context.open ? "open" : "closed",
                }),
            );
        }

        return (
            <button
                ref={ref}
                type="button"
                // The user can style the trigger based on the state
                data-state={context.open ? "open" : "closed"}
                {...context.getReferenceProps(props)}
            >
                {children}
            </button>
        );
    },
);

export type PopoverContentProps = {
    variant?:
        | "default"
        | "filter-box"
        | "centered"
        | "empty"
        | "lcv-filter"
        | "action-panel"
        | "grade-switcher"
        | "compare-selector";
    usePortal?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const PopoverContent = forwardRef<HTMLDivElement, PopoverContentProps>(({ usePortal, ...props }, propRef) => {
    // Disabled because useTransitionStyles needs a context and will have one in 99% of times
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const context = usePopoverContext()!;

    const ref = useMergeRefs([context?.refs.setFloating, propRef]);

    const { isMounted, styles } = useTransitionStyles(context.context, {
        duration: 100,
    });

    const Container = usePortal === false ? Fragment : FloatingPortal;
    return (
        <Container>
            {isMounted && (
                <FloatingFocusManager context={context.context} modal={context.modal}>
                    <Floater
                        ref={ref}
                        position={{ x: context.x ?? 0, y: context.y ?? 0 }}
                        arrowPosition={context.middlewareData.arrow}
                        strategy={context.strategy}
                        placement={context.placement}
                        arrowCallback={context.arrowCallback}
                        aria-labelledby={context.labelId}
                        aria-describedby={context.descriptionId}
                        {...context.getFloatingProps(props)}
                        style={styles}
                        variant={props.variant ?? "default"}
                    >
                        {props.children}
                    </Floater>
                </FloatingFocusManager>
            )}
        </Container>
    );
});

const PopoverClose = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement>>(({ children, ...props }, ref) => {
    const context = usePopoverContext();

    const onClick = useCallback(() => {
        context?.setOpen(false);
    }, [context]);

    if (isValidElement(children)) {
        return cloneElement(children, {
            onClick,
            ...props,
        });
    }

    return (
        <button onClick={onClick} {...props} ref={ref} type="button">
            {children}
        </button>
    );
});

// Based on: https://floating-ui.com/docs/popover
const Popover: FC<
    {
        children: ReactNode;
    } & PopoverOptions
> & {
    Trigger: typeof PopoverTrigger;
    Close: typeof PopoverClose;
    Content: typeof PopoverContent;
} = ({ children, modal = false, ...restOptions }) => {
    // This can accept any props as options, e.g. `placement`,
    // or other positioning options.
    const popover = usePopover({ modal, ...restOptions });
    return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
};

Popover.Trigger = PopoverTrigger;
Popover.Close = PopoverClose;
Popover.Content = PopoverContent;

if (process.env.NODE_ENV === "development") {
    PopoverClose.displayName = "PopoverClose";
    PopoverTrigger.displayName = "PopoverTrigger";
    PopoverContent.displayName = "PopoverContent";
}

export default Popover;
