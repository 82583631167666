import React, { useEffect } from "react";
import clsx from "clsx";
import Modal from "../../../../../shared-components/css-modules/toyota/organisms/Modal";
import type { ModalViewType } from "../../../../../../../common-deprecated/types/CommonTypes";
import type { GradeSelectorFilterModalType } from "../../../../utils/modalConstants";
import styles from "./MobileFilterModal.module.scss";
import Button from "../../../../../shared-components/css-modules/toyota/atoms/Button";
import Icon from "../../../../../shared-components/css-modules/toyota/atoms/Icon";
import Text from "../../../../../shared-components/css-modules/toyota/atoms/Text";
import { GradeSelectorLabel } from "../../../../utils/labels";
import Checkbox from "../../../../../shared-components/css-modules/toyota/molecules/Checkbox";
import Radio from "../../../../../shared-components/css-modules/toyota/molecules/Radio";
import filterButtonStyles from "../../selector/actions/filter/FilterButton.module.scss";
import useMobileFilterModal from "../../../../hooks/useMobileFilterModal";

const FilterModal = (props: ModalViewType<GradeSelectorFilterModalType>): JSX.Element => {
    const { show } = props;

    const filterModal = useMobileFilterModal();
    const { handleColourInput, handleRadioInput, onClose, isFilterDisabled, confirmSelection } = filterModal;
    const { closeLabel, resultButtonLabel, isResultButtonDisabled, filters, activeFilters, gradeBodyTypes } =
        filterModal;

    // Close modal on escape key press to prevent user from being stuck due to boy overflow being hidden.
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                event.preventDefault();
                onClose();
            }
        };
        document.addEventListener("keydown", handleKeyDown, true);

        return () => {
            document.removeEventListener("keydown", handleKeyDown, true);
        };
    }, [onClose]);

    return (
        <Modal variant="filter" open={show}>
            <div>
                <header className={styles["filter-modal-header"]}>
                    <Button onClick={onClose} variant="clean">
                        <Icon variant="close" sizing="md" aria-hidden="true" />
                        <Text>{closeLabel}</Text>
                    </Button>
                    <Button onClick={confirmSelection} disabled={isResultButtonDisabled} variant="btn-default">
                        <GradeSelectorLabel
                            label={resultButtonLabel}
                            params={{ amount: gradeBodyTypes.length.toString() }}
                        />
                    </Button>
                </header>
                <div className={styles["filter-modal-content"]}>
                    {filters.map((filter) => (
                        <section key={filter.filterId} className={styles["filter-modal-section"]}>
                            <GradeSelectorLabel label={filter.key} />
                            <div
                                className={clsx(
                                    filterButtonStyles["filter-button-content"],
                                    styles["filter-button-content"],
                                )}
                            >
                                {filter.data.map((value) => {
                                    const isDisabled = isFilterDisabled(filter.filterId, value);
                                    return value.swatch ? (
                                        <Checkbox
                                            disabled={isDisabled}
                                            key={value.code}
                                            label={value.name}
                                            swatch={value.swatch}
                                            className={filterButtonStyles.swatch}
                                            onChange={() =>
                                                !isDisabled && handleColourInput(filter.filterId, value.code)
                                            }
                                            checked={
                                                !!activeFilters[filter.filterId] &&
                                                activeFilters[filter.filterId].includes(value.code)
                                            }
                                        />
                                    ) : (
                                        <Radio
                                            disabled={isDisabled}
                                            key={value.code}
                                            label={value.name}
                                            checked={activeFilters[filter.filterId] === value.code}
                                            onClick={() => !isDisabled && handleRadioInput(filter.filterId, value.code)} // Radio needs to be unchecked when clicked.
                                            onChange={() => null} // Needed to remove console errors.
                                            amount={value.uniqueGradeBodyTypeIds.length}
                                        />
                                    );
                                })}
                            </div>
                        </section>
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
