import type { PropsWithChildren } from "react";
import React, { memo, useCallback, useEffect, useMemo, useRef, forwardRef, useImperativeHandle } from "react";

import clsx from "clsx";
import Icon from "../atoms/Icon";
import Button from "../atoms/Button";
import Text from "../atoms/Text";
import styles from "./SidePanel.module.scss";
import { useCloseOnOutsideDialog } from "../../../../../../shared-logic/hooks/useCloseOnOutsideDialog";

type Props = PropsWithChildren<{
    variant?: "default" | "fullscreen" | "split-panel" | "summary";
    fromDirection?: "inline-start" | "inline-end" | "block-start" | "block-end" | "none";
    mobileFromDirection?: "block-start" | "block-end" | "none";
    onRequestClose?: () => void;
    title?: string;
    topLayer?: boolean;
    className?: string;
    offset?: boolean;
}> &
    React.HTMLProps<HTMLDialogElement>;

const SidePanel = forwardRef<HTMLDialogElement, Props>(
    (
        {
            open,
            variant = "default",
            fromDirection = "inline-start",
            mobileFromDirection,
            title,
            children,
            onRequestClose,
            topLayer = true,
            className,
            offset = true,
            ...rest
        },
        initialRef,
    ) => {
        const ref = useRef<HTMLDialogElement>(null);

        useImperativeHandle(initialRef, () => ref.current as HTMLDialogElement, []);

        const dialogClasses = useMemo(() => {
            return clsx(
                styles[variant],
                styles[fromDirection],
                mobileFromDirection && styles[`mobile-${mobileFromDirection}`],
                !topLayer && styles["out-of-toplayer"],
                !title && styles["no-title"],
                offset && styles.offset,
                className,
            );
        }, [open, fromDirection, mobileFromDirection, topLayer, variant, className]);

        // In cases toplayer is not supported, this is rare, only summary and othergrades modal
        // If possible, this should be removed from the moment we can provide toplayer functionality.
        // This is usually due to external components injected to before the body closing tag by makolab
        useEffect(() => {
            if (open) {
                if (topLayer) {
                    ref.current?.showModal();
                } else if (variant === "summary") {
                    ref.current?.show();
                    document.body?.classList.add("or-apheleia-summary-open");
                } else {
                    ref.current?.show();
                    document.body?.classList.add("or-apheleia-sidepanel-open");
                }
            }
        }, [open, topLayer]);

        const closeSidepanel = useCallback(() => {
            onRequestClose?.();
            // In cases toplayer is not supported, this is rare, only summary and othergrades modal
            document.body?.classList.remove("or-apheleia-sidepanel-open", "or-apheleia-summary-open");
            ref.current?.close();
        }, [onRequestClose]);

        useCloseOnOutsideDialog(ref, closeSidepanel, !open);

        return (
            <>
                <dialog ref={ref} {...rest} onCancel={closeSidepanel} className={dialogClasses}>
                    <div className={styles.content}>
                        {title ? (
                            <header className={styles.header}>
                                {title && (
                                    <Text tag="h2" variant="apheleia-h3" className={styles.title}>
                                        {title}
                                    </Text>
                                )}
                                <Button
                                    variant="btn-default"
                                    circle
                                    sizing="sm"
                                    onClick={closeSidepanel}
                                    aria-label="close"
                                >
                                    <Icon variant="close" sizing="md" aria-hidden="true" />
                                </Button>
                            </header>
                        ) : (
                            <header className={styles["header-no-title"]}>
                                <Button
                                    variant="btn-default"
                                    circle
                                    onClick={closeSidepanel}
                                    aria-label="close"
                                    className={styles.close}
                                >
                                    <Icon variant="close" sizing="md" aria-hidden="true" />
                                </Button>
                            </header>
                        )}
                        {children}
                    </div>
                </dialog>
                {!topLayer && (
                    <button
                        type="button"
                        className={styles.backdrop}
                        onClick={closeSidepanel}
                        aria-label="closeSidepanel"
                    />
                )}
            </>
        );
    },
);

export default memo(SidePanel);
