import { useEffect, useState, useCallback } from "react";
import { useWindowDimensionsContext } from "../../common-deprecated/containers/WindowDimensionsProvider";

const getZoomLevel = (innerWidth: number, outerWidth: number): number => {
    if (typeof outerWidth === "number" && typeof innerWidth === "number") {
        return outerWidth / innerWidth;
    }
    return 1;
};

const getDevicePxRatio = (): number => {
    if (typeof window.devicePixelRatio === "number") {
        return window.devicePixelRatio;
    }
    return 1;
};

/**
 * Hook to detect zoom level changes in the browser.
 * @param zoomScale - Optional zoom scale to compare against. Default is 1.2.
 * @returns boolean - True if the browser is zoomed in.
 */
export const useZoom = (zoomScale?: number): boolean => {
    const ZOOM_SCALE = zoomScale || 1.2;
    const currentPxRatio = 1;
    const [isZoomedIn, setIsZoomedIn] = useState(false);
    const { width: innerWidth, outerWidth, viewportWidth } = useWindowDimensionsContext();

    const handleZoomChange = useCallback(() => {
        const zoom = getZoomLevel(innerWidth, outerWidth);
        // Check if the ratios has changed
        const scaled = zoom > ZOOM_SCALE;
        // But this will fail when the browser developer tools are open,
        // So we need to check also if the device pixel ratio is changed
        const newPxRatio = getDevicePxRatio();
        const zoomed = newPxRatio >= ZOOM_SCALE && newPxRatio !== currentPxRatio;
        setIsZoomedIn(scaled && zoomed);
    }, [ZOOM_SCALE, innerWidth, outerWidth]);

    useEffect(() => {
        handleZoomChange();
    }, [handleZoomChange]);

    // Handle pinch-to-zoom events using visualViewport API if available
    useEffect(() => {
        // Only process if window.width hasn't changed
        // This prevents duplicate events when both viewport and window resize
        if (innerWidth === viewportWidth) {
            handleZoomChange();
        }
    }, [handleZoomChange, innerWidth, viewportWidth]);

    return isZoomedIn;
};
