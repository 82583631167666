import React from "react";
import styles from "./DebugModals.module.scss";
import Text from "../../../shared-components/css-modules/toyota/atoms/Text";
import Modal from "../../../shared-components/css-modules/toyota/organisms/Modal";
import { useCommonSelector } from "../../../../../common-deprecated/redux/commonStore";
import {
    checkAemFlexibilityMatrixFinanceOrInsuranceEnabled,
    getAemFlexibilityMatrixConfig,
    getAemPriceConfig,
    showAemPriceExclVAT,
} from "../../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent } from "../../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { StatusEntryType } from "../../../../../common-deprecated/components/modals/status/StatusModalEntry";
import { useGradeSelectorSelector } from "../../redux/hooks";
import type { DebugFinanceModalType } from "../../utils/modalConstants";
import type { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";

const FinanceModal = (props: ModalViewType<DebugFinanceModalType>): JSX.Element => {
    const { show, close } = props;
    const commonSettings = useCommonSelector((state) => state.commonSettings);

    const model = useGradeSelectorSelector((state) => state.gradeSelectorModel.model);
    const modelCode = model.code;
    const gradeSelectorFinance = useGradeSelectorSelector((state) => state.gradeSelectorFinance);

    const entries: StatusEntryType[] = [
        {
            title: "Finance toggle flags",
            description: "Finance/cash toggles are shown when all flags below are true.",
            values: [
                {
                    label: "Flexibility matrix: finance enabled",
                    value: checkAemFlexibilityMatrixFinanceOrInsuranceEnabled(
                        "finance",
                        commonSettings,
                        AemFmComponent.GradeSelector,
                        "new",
                        modelCode,
                    ),
                },
                {
                    label: "Prices are not ExclVAT",
                    value: !showAemPriceExclVAT(commonSettings, AemFmComponent.GradeSelector, "new", modelCode),
                },
            ],
        },
        {
            title: "Tax and costs flags",
            values: [
                {
                    label: "Country (needs to be fr to show registration tax)",
                    value: commonSettings.country,
                },
            ],
        },
        {
            title: "Monthly rates",
            values: Object.keys(gradeSelectorFinance.cars).length
                ? [
                      {
                          label: "Finance Gateway Response",
                          value: true,
                      },
                      ...Object.entries(gradeSelectorFinance.cars)
                          .map(([key, car], index) => [
                              {
                                  label: `Entry ${index + 1}: Finance Info - ${key}`,
                                  collapsibleContent: JSON.stringify(car.financeInfo, undefined, 2),
                              },
                              {
                                  label: `Entry ${index + 1}:Finance Quote - ${key}`,
                                  collapsibleContent: JSON.stringify(car.quote, undefined, 2),
                              },
                          ])
                          .flat(),
                  ]
                : [{ label: "Finance Gateway Response", value: false }],
        },
        {
            title: "AEM Flexibility matrix settings",
            values: [
                {
                    label: "component",
                    value: getAemFlexibilityMatrixConfig(commonSettings, AemFmComponent.GradeSelector, "new", modelCode)
                        ?.component as string,
                },
                {
                    label: "last updated",
                    value: getAemFlexibilityMatrixConfig(commonSettings, AemFmComponent.GradeSelector, "new", modelCode)
                        ?.lastModified,
                },
                { label: "model code", value: modelCode },
                {
                    label: "getPriceConfig priceFormat",
                    value: getAemPriceConfig(commonSettings, AemFmComponent.GradeSelector, modelCode).priceFormat,
                },
                {
                    label: "getPriceConfig priceType",
                    value: getAemPriceConfig(commonSettings, AemFmComponent.GradeSelector, modelCode)
                        .priceType as string,
                },
                {
                    label: "showPriceExcl",
                    value: showAemPriceExclVAT(commonSettings, AemFmComponent.GradeSelector, "new", modelCode),
                },
                {
                    label: "Parsed AEM Flexibility Matrix Config",
                    collapsibleContent: JSON.stringify(
                        getAemFlexibilityMatrixConfig(commonSettings, AemFmComponent.GradeSelector, "new", modelCode),
                        undefined,
                        2,
                    ),
                },
            ],
        },
    ];
    return (
        <Modal open={show} onRequestClose={close}>
            <div className={styles["debug-modal"]}>
                <Text tag="h2" variant="apheleia-h2" spacing="spacing-3">
                    Finance info
                </Text>
                {entries.map((entry) => (
                    <div
                        key={entry.title}
                        style={{
                            marginBottom: 32,
                        }}
                    >
                        {!!(entry.title || entry.description) && (
                            <div
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                {entry.title && (
                                    <Text tag="h3" variant="apheleia-h3">
                                        {entry.title}
                                    </Text>
                                )}
                                {entry.description && <Text>{entry.description}</Text>}
                            </div>
                        )}

                        <div className={styles.table}>
                            <table>
                                <tbody>
                                    {entry.values &&
                                        entry.values.map((value, index) => {
                                            if (value === null) return null;

                                            if (value.collapsibleContent) {
                                                return (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <tr key={`${value.label}${index}`}>
                                                        <td colSpan={2}>
                                                            <details>
                                                                <summary>{value.label}</summary>
                                                                <pre>{value.collapsibleContent}</pre>
                                                            </details>
                                                        </td>
                                                    </tr>
                                                );
                                            }

                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <tr key={`${value.label}${index}`}>
                                                    {value.long ? (
                                                        <td colSpan={2}>
                                                            <div>{value.label}</div>
                                                            <div>
                                                                {value.value && typeof value.value !== "undefined"
                                                                    ? value.value
                                                                    : "n/a"}
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <>
                                                            <td>{value.label}</td>
                                                            <td>
                                                                {typeof value.value !== "undefined"
                                                                    ? String(value.value)
                                                                    : "undefined"}
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default FinanceModal;
