import React, { useState } from "react";
import Modal from "../../../../../shared-components/css-modules/toyota/organisms/Modal";
import type { ModalViewType } from "../../../../../../../common-deprecated/types/CommonTypes";
import { ProaceFilterType } from "../../../../../../../common-deprecated/types/CommonTypes";
import type { GradeSelectorLcvFilterModalType } from "../../../../utils/modalConstants";
import styles from "./MobileLcvFilterModal.module.scss";
import lcvFilterStyles from "../../lcv-filter/LcvFilters.module.scss";
import Button from "../../../../../shared-components/css-modules/toyota/atoms/Button";
import Icon from "../../../../../shared-components/css-modules/toyota/atoms/Icon";
import { GradeSelectorLabel } from "../../../../utils/labels";
import useLcvFilters from "../../../../hooks/useLcvFilters";
import { CommonLabel } from "../../../../../../../common-deprecated/utils/commonLabels";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import type { LcvFilterType } from "../../../../utils/types/LcvFilter";
import { useFilteredGradeBodyTypes } from "../../../../utils/filterHooks";
import { getResultButtonLabel } from "../../../../utils/filterUtils";

type FilterDiscloserItemPropsType = {
    filter: LcvFilterType;
    activeFilters: Record<string, string>;
    activeFilter: string;
    setActiveFilter: React.Dispatch<React.SetStateAction<string>>;
};

const FilterDisclosureItem = ({
    filter,
    activeFilters,
    activeFilter,
    setActiveFilter,
}: FilterDiscloserItemPropsType): JSX.Element => {
    const lcvFilters = useLcvFilters();

    return (
        <section key={filter.code} className={styles["filter-section"]}>
            <h2 className={styles["filter-section-title"]}>
                <button
                    type="button"
                    aria-expanded={activeFilter === filter.code}
                    onClick={() => setActiveFilter(filter.code)}
                >
                    {filter.label} <Icon variant="chevron-down" sizing="sm" aria-hidden="true" />
                </button>
            </h2>
            {filter.values.length > 0 && (
                <div className={styles["filter-section-content"]} hidden={activeFilter !== filter.code || undefined}>
                    <ul className={lcvFilterStyles["filter-item-options"]} role="list">
                        {filter.values.map((filterValue) => (
                            <li
                                className={lcvFilterStyles["filter-item-option"]}
                                key={`${filter.code}-${filterValue.id}`}
                            >
                                <div className={lcvFilterStyles["radio-wrapper"]}>
                                    <input
                                        type="radio"
                                        id={`${filter.code}-${filterValue.id}`}
                                        name={filter.code}
                                        disabled={!lcvFilters?.isFilterValueEnabled(filter.code, filterValue.id)}
                                        onChange={() => lcvFilters?.setFilterValue(filter.code, filterValue.id)}
                                        checked={activeFilters[filter.code] === filterValue.id}
                                    />
                                    <label
                                        htmlFor={`${filter.code}-${filterValue.id}`}
                                        className={
                                            filter.code === ProaceFilterType.SeatsCount
                                                ? lcvFilterStyles.reverse
                                                : undefined
                                        }
                                    >
                                        {filterValue.assets && (
                                            <img
                                                src={filterValue.assets.src}
                                                srcSet={filterValue.assets.srcSet}
                                                alt={filterValue.assets.alt}
                                            />
                                        )}
                                        {filterValue.label}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </section>
    );
};

const FilterModal = (props: ModalViewType<GradeSelectorLcvFilterModalType>): JSX.Element | null => {
    const { show, close } = props;
    const { activeFilters, filters } = useGradeSelectorSelector((state) => state.gradeSelectorLcvFilters);
    const [activeFilter, setActiveFilter] = useState<string>("");
    const lcvFilters = useLcvFilters();
    const filteredGradeBodyTypes = useFilteredGradeBodyTypes();

    const onClose = (): void => {
        lcvFilters?.setOpen(false);
        close();
    };

    if (!lcvFilters) return null;

    const amount = filteredGradeBodyTypes.length;

    return (
        <Modal variant="lcv-filter" open={show}>
            <div>
                <header className={styles["filter-modal-header"]}>
                    <Button onClick={onClose} variant="btn-icon-text" sizing="lg">
                        <Icon variant="close" sizing="sm" aria-hidden="true" />
                        <CommonLabel label="close" />
                    </Button>
                    <Button onClick={onClose} variant="btn-default" disabled={!amount || !lcvFilters.hasActiveFilters}>
                        <GradeSelectorLabel
                            label={getResultButtonLabel(!amount || !lcvFilters.hasActiveFilters, amount)}
                            params={{ amount: `${amount}` }}
                        />
                    </Button>
                </header>
                <div className={styles["filter-modal-content"]}>
                    {filters.map((filter) => (
                        <FilterDisclosureItem
                            filter={filter}
                            activeFilters={activeFilters}
                            activeFilter={activeFilter}
                            setActiveFilter={(value) => setActiveFilter(value)}
                            key={filter.code}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export default FilterModal;
