import { useMemo } from "react";
import { useGradeSelectorSelector } from "../redux/hooks";
import { selectModel } from "../redux/slices/GradeSelectorModelSlice";
import type { CarType, GradeBodyTypeType } from "./types/Model";
import { applyFilters, applyLcvFilters, hasActiveFilters as checkActiveFilters, mergeBodyTypes } from "./filterUtils";
import useLcvFilters from "../hooks/useLcvFilters";

export const useFilteredGradeBodyTypes = (): GradeBodyTypeType[] => {
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorFilters);
    const lcvFilters = useLcvFilters();

    const hasActiveFilters = checkActiveFilters(activeFilters);

    const { gradeBodyTypes } = useGradeSelectorSelector(selectModel);

    return useMemo(() => {
        if (lcvFilters && lcvFilters.hasActiveFilters) {
            return applyLcvFilters(gradeBodyTypes, lcvFilters.filteredCars);
        } else {
            if (!hasActiveFilters) {
                return gradeBodyTypes;
            }

            return applyFilters(activeFilters, gradeBodyTypes);
        }
    }, [lcvFilters, gradeBodyTypes, hasActiveFilters, activeFilters]);
};

export const useFilterCarExtras = (car: CarType): string[] => {
    const activeFilters = useGradeSelectorSelector((state) => state.gradeSelectorLcvFilters.activeFilters);
    const filteredCars = useLcvFilters()?.filteredCars;

    return useMemo(() => {
        if (!filteredCars) return [];
        return (
            filteredCars
                ?.find((filteredCar) => filteredCar.id === car.CarID)
                ?.filterValues.map(({ id, code, extras }) => {
                    if (activeFilters[code] !== id) return [];
                    return extras;
                }) || []
        )
            .flat()
            .filter(Boolean);
    }, [filteredCars, car, activeFilters]);
};

// Retailer variant of useFilteredGradeBodyTypes, additional sorting is needed here.
export const useRetailerFilteredGradeBodyTypes = (hasFilter: boolean): GradeBodyTypeType[] => {
    const allGradeBodyTypes = useGradeSelectorSelector((state) => state.gradeSelectorModel.model.gradeBodyTypes);
    const hasLcvFilters = useGradeSelectorSelector(
        (state) => state.gradeSelectorLcvFilters.initialized && state.gradeSelectorLcvFilters.filters.length > 0,
    );
    const filteredGradeBodyTypes = useFilteredGradeBodyTypes();

    // Important useMemo to reduce amount of rerenders.
    const sortedBodyTypes = useMemo(() => {
        return mergeBodyTypes(allGradeBodyTypes);
    }, [allGradeBodyTypes]);

    return hasFilter || hasLcvFilters ? filteredGradeBodyTypes : sortedBodyTypes;
};
