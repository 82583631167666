import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import type { GradeSelectorStateType } from "./redux/store";
import { gradeSelectorClientStore } from "./redux/store";
import Globalize from "../../../common-deprecated/Globalize";
import { renderReact } from "../../../common-deprecated/utils/clientRenderUtils";
import { ModalProvider } from "../../../common-deprecated/containers/ModalProvider";
import { removeLastSlashAndWhiteSpace } from "../../../common-deprecated/utils";
import { WindowDimensionsProvider } from "../../../common-deprecated/containers/WindowDimensionsProvider";
import type { CommonSettingsType } from "../../../common-deprecated/settings/fetchCommonSettings";

export const getInit =
    (GradeSelector: React.ElementType, waitFn?: (commonSettings: CommonSettingsType) => Promise<void>) =>
    async (target: string, props: unknown, initialState: GradeSelectorStateType): Promise<void> => {
        const { commonSettings } = initialState;
        const { country, culture } = commonSettings;

        // eslint-disable-next-line camelcase
        __webpack_public_path__ = `${removeLastSlashAndWhiteSpace(commonSettings.resourcePath)}/`;

        // Initialize store, tagging and globalize.
        const store = gradeSelectorClientStore(initialState, commonSettings.component);
        Globalize(country, culture);

        if (waitFn) await waitFn(commonSettings);

        renderReact(
            target,
            commonSettings,
            // Since react-redux 8, new development checks were introduced against incorrect usages of useSelector, mostly to detect unnecessary rerenders.
            // These checks were disabled for all existing components as these create quite some issues
            // Ideally these checks should be enabled in all components - UC-1220
            // Notice: Make sure to not add the 'stabilityCheck="never"' in new components
            <ReduxProvider store={store} stabilityCheck="never">
                <WindowDimensionsProvider>
                    <ModalProvider>
                        <GradeSelector />
                    </ModalProvider>
                </WindowDimensionsProvider>
            </ReduxProvider>,
        );
    };
