import type {
    MODAL_RETAILER_DEBUG,
    RetailerDebugModalType,
} from "../../../../shared-logic/features/retailer/utils/modalConstants";
import type { MODAL_ERRORS, ModalErrorsType } from "../../../../common-deprecated/utils/modalConstants";
import type { CmcModelDataType } from "../../shared-logic/types/CmcModelDataType";

export const MODAL_DEBUG_FINANCE = "debugFinance" as const;
export const MODAL_DEBUG_LCV_FILTERS = "debugLcvFilters" as const;
export const MODAL_GRADE_SELECTOR_COMPARE = "gradeSelectorCompare" as const;
export const MODAL_MOBILE_GRADE_SELECTOR_FILTER = "gradeSelectorFilter" as const;
export const MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER = "gradeSelectorLcvFilter" as const;

export type GradeSelectorModalIdsType =
    | typeof MODAL_DEBUG_FINANCE
    | typeof MODAL_DEBUG_LCV_FILTERS
    | typeof MODAL_RETAILER_DEBUG
    | typeof MODAL_GRADE_SELECTOR_COMPARE
    | typeof MODAL_MOBILE_GRADE_SELECTOR_FILTER
    | typeof MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER
    | typeof MODAL_ERRORS;

export type DebugFinanceModalType = {
    type: typeof MODAL_DEBUG_FINANCE;
};

export type GradeSelectorCompareModalType = {
    type: typeof MODAL_GRADE_SELECTOR_COMPARE;
    models: CmcModelDataType[];
};

export type GradeSelectorFilterModalType = {
    type: typeof MODAL_MOBILE_GRADE_SELECTOR_FILTER;
};

export type GradeSelectorLcvFilterModalType = {
    type: typeof MODAL_MOBILE_GRADE_SELECTOR_LCV_FILTER;
};

export type DebugLcvFiltersModalType = {
    type: typeof MODAL_DEBUG_LCV_FILTERS;
};

export type GradeSelectorModalsType =
    | DebugFinanceModalType
    | DebugLcvFiltersModalType
    | GradeSelectorCompareModalType
    | RetailerDebugModalType
    | GradeSelectorFilterModalType
    | GradeSelectorLcvFilterModalType
    | ModalErrorsType;
