import { propagateCommonQueryParamsAsString } from "../../../../common-deprecated/utils";
import { environmentMap } from "../../../../common-deprecated/utils/environments";
import type { GradeSelectorStateType } from "../redux/store";
import type { EquipmentGroupType } from "./types/Model";
import {
    getAemPriceConfig,
    showAemMonthlyRates,
} from "../../../../common-deprecated/settings/utils/financeSettingUtilsAem";
import { AemFmComponent, AemFmPriceType } from "../../../../common-deprecated/utils/aemFlexibilityMatrixUtils";
import type { ApiPriceType } from "../../../../common-deprecated/types/ApiModel";

/**
 * Returns all equipmentGroups where there is at least 1 equipment
 * @param state
 */
export const tabSelector = (state: GradeSelectorStateType): EquipmentGroupType[] => {
    const { equipment, equipmentGroups } = state.gradeSelectorModel.model;
    return equipmentGroups.filter(({ path }) => equipment.find(({ groupPath }) => groupPath === path));
};

export const standaloneModelFilterUrlSelector = (state: GradeSelectorStateType): string => {
    const { commonSettings } = state;
    const { country, language, environment, brand, resourcePath } = commonSettings;
    const queryParam = propagateCommonQueryParamsAsString(commonSettings);
    const backToModelPath = `${environmentMap.build.entrypoints.modelfilter
        .replace("{country}", country)
        .replace("{language}", language)}${queryParam.length === 0 ? "" : `?${queryParam}`}`;

    // Model-filter can't exist on aph-webcarconfig.
    if (resourcePath === environmentMap.apheleia.domains[environment].replace("{brand}", brand))
        return environmentMap.build.domains[environment].replace("{brand}", brand) + backToModelPath;

    return backToModelPath;
};

/**
 * Selector to determine if the price is enabled AND available.
 */
export const priceAvailabilitySelector =
    (carId: string, price: ApiPriceType, selectedColourId?: string) =>
    (state: GradeSelectorStateType): boolean => {
        const { commonSettings } = state;
        const modelCode = state.gradeSelectorModel.model.code;
        const priceConfig = getAemPriceConfig(commonSettings, AemFmComponent.GradeSelector, modelCode);
        const monthlyEnabled = showAemMonthlyRates(commonSettings, AemFmComponent.GradeSelector, "new", modelCode);
        const { state: financeState, cars } = state.gradeSelectorFinance;
        const financeId = `${carId}-${selectedColourId as string}`;

        const priceToUse =
            priceConfig.priceType === AemFmPriceType.InclVat ? price.listWithDiscount : price.netWithDiscount;

        const carFinance = cars[financeId];

        return (
            (priceConfig.priceEnabled && !!priceToUse) ||
            (monthlyEnabled && financeState !== "complete" && !!carFinance)
        );
    };
