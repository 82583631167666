import type { CSSProperties, PropsWithChildren } from "react";
import React, { forwardRef } from "react";
import clsx from "clsx";
import type { Coords, Placement, Strategy } from "@floating-ui/react";

import styles from "./Floater.module.scss";
import { useZoom } from "../../../../../../shared-logic/hooks/useZoom";

export const ARROW_SIZE = 10;

const Floater = forwardRef<
    HTMLDivElement,
    PropsWithChildren<{
        // state: TooltipState | PopoverState | DialogState;
        position: { x: number | null; y: number | null };
        arrowPosition?: Partial<Coords>;
        strategy: Strategy;
        placement: Placement;
        arrowCallback?: (node: HTMLDivElement | null) => void;
        showArrow?: boolean;
        style: CSSProperties;
        className?: string;
        variant?:
            | "default"
            | "filter-box"
            | "empty"
            | "lcv-filter"
            | "action-panel"
            | "grade-switcher"
            | "compare-selector"
            | "centered";
    }>
>(
    (
        {
            children,
            position,
            arrowPosition,
            strategy,
            placement,
            arrowCallback,
            showArrow = true,
            variant = "default",
            className,
            ...props
        },
        ref,
    ) => {
        const placementFirst = placement.split("-")[0];
        const staticSide = {
            top: "bottom",
            right: "left",
            bottom: "top",
            left: "right",
        }[placementFirst] as string;
        const rotation = {
            top: "rotate(135deg)",
            right: "rotate(-135deg)",
            bottom: "rotate(-45deg)",
            left: "rotate(45deg)",
        }[placementFirst] as string;
        const isZoomedIn = useZoom();

        return (
            <div
                className={clsx(styles[variant], className)}
                ref={ref}
                {...props}
                style={{
                    transform: `translate3d(${Math.round(position.x ?? 0)}px, ${Math.round(position.y ?? 0)}px, 0)`,
                    position: strategy,
                    visibility: position.x == null ? "hidden" : "visible",
                    ...props.style,
                }}
                data-browser-is-zoomed={isZoomedIn || undefined}
            >
                {children}
                {showArrow && arrowCallback && (
                    <div
                        className={styles.arrow}
                        ref={arrowCallback}
                        style={{
                            left: arrowPosition?.x ? `${arrowPosition.x}px` : "",
                            top: arrowPosition?.y ? `${arrowPosition.y}px` : "",
                            [staticSide]: `-${ARROW_SIZE / 2}px`,
                            transform: rotation,
                        }}
                    />
                )}
            </div>
        );
    },
);

if (process.env.NODE_ENV === "development") {
    Floater.displayName = "Floater";
}

export default Floater;
