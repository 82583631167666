import React, { Fragment } from "react";
import styles from "../../../../shared-components/debug-modals/DebugModals.module.scss";
import Text from "../../../../../shared-components/css-modules/toyota/atoms/Text";
import Modal from "../../../../../shared-components/css-modules/toyota/organisms/Modal";
import type { ModalViewType } from "../../../../../../../common-deprecated/types/CommonTypes";
import type { DebugLcvFiltersModalType } from "../../../../utils/modalConstants";
import { useGradeSelectorSelector } from "../../../../redux/hooks";
import type { StatusEntryType } from "../../../../../../../common-deprecated/components/modals/status/StatusModalEntry";
import { modelIsLcv } from "../../../../../../shared-logic/utils/lcvUtils";
import useLcvFilters from "../../../../hooks/useLcvFilters";
import type { LcvFilterCarType } from "../../../../utils/types/LcvFilter";

export const LcvFiltersDebugModal = (props: ModalViewType<DebugLcvFiltersModalType>): JSX.Element => {
    const { show, close } = props;

    const model = useGradeSelectorSelector((state) => state.gradeSelectorModel.model);
    const { filters, cars } = useGradeSelectorSelector((state) => state.gradeSelectorLcvFilters);
    const lcvFilters = useLcvFilters();
    const canHaveFilters = modelIsLcv(model.id);

    // Check if each car actually has all filters defined, if not, log a message.
    const [validCars, invalidCars] = cars.reduce(
        ([valid, invalid]: [LcvFilterCarType[], LcvFilterCarType[]], car) => {
            const carIsValid = filters.every((filter) =>
                car.filterValues.find((carFilterValue) => filter.code === carFilterValue.code),
            );
            return carIsValid ? [[...valid, car], invalid] : [valid, [...invalid, car]];
        },
        [[], []],
    );

    const getFilterValueLabel = (filterCode: string, filterValueId: string): string => {
        const filter = filters.find(({ code }) => code === filterCode);
        const value = filter?.values.find((val) => val.id === filterValueId);
        return value?.label || "";
    };

    const configurationEntries: (StatusEntryType & { key: string })[] = [
        {
            key: "default-config",
            title: "Default Filter configuration",
            description: "All default filters should be configured.",
            values: filters.map((filter) => ({
                label: `${filter.code}: ${filter.label}`,
                value: filter.default,
                long: true,
                collapsibleContent: JSON.stringify(
                    {
                        defaultID: filter.default,
                        defaultValue: filter.values.find((val) => val.id === filter.default)?.label,
                        values: filter.values.reduce(
                            (dict: Record<string, string>, val) => ({
                                ...dict,
                                [val.id]: val.label,
                            }),
                            {},
                        ),
                    },
                    undefined,
                    2,
                ),
            })),
        },
        ...invalidCars.map((car) => ({
            key: `${car.id}-${car.filterValues.map(({ id }) => id).join("-")}`,
            title: car.name,
            description: "This vehicle has missing filters.",
            values: filters.map((filter) => {
                const filterValue = car.filterValues.find((carFilterValue) => filter.code === carFilterValue.code);
                return {
                    label: `${filter.code}: ${filter.label}`,
                    value: filterValue
                        ? `Present: ${getFilterValueLabel(filter.code, filterValue.id) || ""}`
                        : `Missing: ${filter.code}`,
                };
            }),
        })),
        ...validCars.map((car) => ({
            key: `${car.id}-${car.filterValues.map(({ id }) => id).join("-")}`,
            title: car.name,
            description: "This vehicle is fully configured.",
            values: filters.map((filter) => {
                const filterValue = car.filterValues.find((carFilterValue) => filter.code === carFilterValue.code);
                return {
                    label: `${filter.code}: ${filter.label}`,
                    value: `Present: ${(filterValue && getFilterValueLabel(filter.code, filterValue.id)) || ""}`,
                };
            }),
        })),
    ];

    const entries: (StatusEntryType & { key: string })[] = [
        {
            key: "root-config",
            title: "Configuration",
            description: "Take an validate your Model configuration and filters.",
            values: [
                {
                    label: "Model can have Filters",
                    value: canHaveFilters,
                },
                {
                    label: "Model has Filters",
                    value: lcvFilters?.enabled,
                },
                ...(lcvFilters?.enabled
                    ? [
                          {
                              label: "Amount of Cars with missing filters",
                              value: `${invalidCars.length}`,
                          },
                          {
                              label: "Amount of Cars with no missing filters",
                              value: `${validCars.length}`,
                          },
                      ]
                    : []),
            ],
        },
        ...(lcvFilters?.enabled ? configurationEntries : []),
    ];

    return (
        <Modal open={show} onRequestClose={close}>
            <div className={styles["debug-modal"]}>
                <Text tag="h2" variant="apheleia-h2" spacing="spacing-3">
                    LCV Filters Info
                </Text>
                {entries.map((entry) => (
                    <div key={entry.key} className={styles.entry}>
                        {!!(entry.title || entry.description) && (
                            <div className={styles["entry-title"]}>
                                {entry.title && (
                                    <Text tag="h3" variant="apheleia-h3">
                                        {entry.title}
                                    </Text>
                                )}
                                {entry.description && <Text>{entry.description}</Text>}
                            </div>
                        )}

                        <div className={styles.table}>
                            <table>
                                <tbody>
                                    {entry.values &&
                                        entry.values.map((value, index) => {
                                            if (value === null) return null;

                                            return (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Fragment key={`${value.label}${index}`}>
                                                    <tr>
                                                        {value.long ? (
                                                            <td colSpan={2}>
                                                                <div className={styles["cell-content"]}>
                                                                    <div>{value.label}</div>
                                                                    <div>
                                                                        <b>
                                                                            {value.value &&
                                                                            typeof value.value !== "undefined"
                                                                                ? value.value
                                                                                : "n/a"}
                                                                        </b>
                                                                    </div>
                                                                </div>
                                                                {value.collapsibleContent && (
                                                                    <details>
                                                                        <summary>{value.label}</summary>
                                                                        <pre>{value.collapsibleContent}</pre>
                                                                    </details>
                                                                )}
                                                            </td>
                                                        ) : (
                                                            <>
                                                                <td>{value.label}</td>
                                                                <td>
                                                                    {typeof value.value !== "undefined"
                                                                        ? String(value.value)
                                                                        : "undefined"}
                                                                </td>
                                                            </>
                                                        )}
                                                    </tr>
                                                </Fragment>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};
