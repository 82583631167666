import React, { useEffect } from "react";
import Selector from "./selector/Selector";
import styles from "./Gradeselector.module.scss";
import { useModalMap } from "../../../../../common-deprecated/containers/ModalProvider";
import { aemGradeSelectorToyotaModalMap } from "./modals/modals";
import {
    POSTAL_CHANNEL_T1_FINANCE,
    POSTAL_TOPIC_UPDATE_DISCLAIMERTABLE,
} from "../../../../../common-deprecated/constants";
import { updateTrackingLayerVersioning } from "../../utils/tracking";
import useDataFetcher from "../../../../../common-deprecated/hooks/useDataFetcher";
import type { GetFetchesFnType } from "../../../../../common-deprecated/utils/registryTypes";
import { COMPONENT_GRADE_SELECTOR } from "../../../../../shared-logic/server/components";
import { getGradeSelectorComponentFetches, gradeSelectorFetchDispatcher } from "../../datafetch/fetchUtils";
import { useGradeSelectorSelector } from "../../redux/hooks";
import useStatus from "../../../../../common-deprecated/hooks/useStatus";
import { getRetailerDebugToggleLink } from "../../../../../common-deprecated/utils";
import { MODAL_DEBUG_FINANCE, MODAL_DEBUG_LCV_FILTERS } from "../../utils/modalConstants";
import { RequestStatusEnum } from "../../../../../common-deprecated/types/CommonTypes";
import useDisclaimerContent from "../../hooks/useDisclaimerContent";
import { postalPublish } from "../../../../../common-deprecated/utils/postalUtils";
import { useZoom } from "../../../../../shared-logic/hooks/useZoom";

const GradeSelector = (): JSX.Element => {
    useModalMap(aemGradeSelectorToyotaModalMap);
    const settings = useGradeSelectorSelector((state) => state.commonSettings);
    const financeIsLoading = useGradeSelectorSelector(
        (state) => state.gradeSelectorFinance.state === RequestStatusEnum.Fetching,
    );
    const disclaimerData = useDisclaimerContent();
    const isZoomedIn = useZoom();

    useEffect(() => {
        postalPublish({
            channel: POSTAL_CHANNEL_T1_FINANCE,
            topic: POSTAL_TOPIC_UPDATE_DISCLAIMERTABLE,
            data: disclaimerData?.minMax || {},
        });
    }, [disclaimerData?.minMax]);

    useEffect(() => {
        updateTrackingLayerVersioning();
    }, []);

    useStatus(
        settings,
        settings.component,
        [getRetailerDebugToggleLink()],
        [
            { key: "f", settings: { type: MODAL_DEBUG_FINANCE } },
            { key: "l", settings: { type: MODAL_DEBUG_LCV_FILTERS } },
        ],
    );

    // This makes the component refetch its data when finance failed to fetch swiftly
    useDataFetcher(
        getGradeSelectorComponentFetches() as unknown as GetFetchesFnType,
        gradeSelectorFetchDispatcher,
        COMPONENT_GRADE_SELECTOR,
        [financeIsLoading],
        [],
    );

    return (
        <div className={styles.gradeselector} data-browser-is-zoomed={isZoomedIn || undefined}>
            <Selector />
        </div>
    );
};

export default GradeSelector;
