import type { Action, Dispatch, ReducersMapObject } from "redux";
import type { CommonActionsType, CommonReducerType } from "../../../../common-deprecated/redux/commonStore";
import UtilReducer from "../../../../common-deprecated/redux/reducers/UtilReducer";
import CommonSettingsReducer from "../../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import ModalReducer from "../../../../common-deprecated/redux/reducers/ModalReducer";
import { getInitToolkitStore } from "../../../../common-deprecated/redux/storeHelpers";
import type {
    GradeSelectorSettingsActionTypes,
    GradeSelectorSettingsType,
} from "./reducers/GradeSelectorSettingsReducer";
import GradeSelectorSettingsReducer from "./reducers/GradeSelectorSettingsReducer";
import type {
    GradeSelectorLcvFiltersType,
    GradeSelectorLcvFiltersActionTypes,
} from "./slices/GradeSelectorLcvFiltersSlice";
import { gradeSelectorLcvFiltersSlice } from "./slices/GradeSelectorLcvFiltersSlice";
import type { GradeSelectorFiltersType } from "./slices/GradeSelectorFiltersSlice";
import { gradeSelectorFiltersSlice } from "./slices/GradeSelectorFiltersSlice";
import type { GradeSelectorModelType } from "./slices/GradeSelectorModelSlice";
import { gradeSelectorModelSlice } from "./slices/GradeSelectorModelSlice";
import type { GradeSelectorFinanceType } from "./slices/GradeSelectorFinanceSlice";
import { gradeSelectorFinanceSlice } from "./slices/GradeSelectorFinanceSlice";
import type { GradeSelectorCompareType, gradeSelectorCompareSlice } from "./slices/GradeSelectorCompareSlice";
import type { CreateLogicType } from "../../../../common-deprecated/redux/typeHelpers";
import gradeSelectorLcvFiltersLogic from "./logic/GradeSelectorLcvFiltersLogic";

export type GradeSelectorStateType = CommonReducerType & {
    gradeSelectorSettings: GradeSelectorSettingsType;
    [gradeSelectorLcvFiltersSlice.name]: GradeSelectorLcvFiltersType;
    [gradeSelectorFiltersSlice.name]: GradeSelectorFiltersType;
    [gradeSelectorModelSlice.name]: GradeSelectorModelType;
    [gradeSelectorFinanceSlice.name]: GradeSelectorFinanceType;
    [gradeSelectorCompareSlice.name]?: GradeSelectorCompareType;
};

export type GradeSelectorActionType =
    | CommonActionsType
    | GradeSelectorSettingsActionTypes
    | GradeSelectorLcvFiltersActionTypes
    | ReturnType<
          | typeof gradeSelectorModelSlice.actions.init
          | typeof gradeSelectorModelSlice.actions.setColour
          | typeof gradeSelectorModelSlice.actions.setSelectedMotorization
          | typeof gradeSelectorFinanceSlice.actions.initFinance
          | typeof gradeSelectorFinanceSlice.actions.setFinanceState
          | typeof gradeSelectorCompareSlice.actions.init
          | typeof gradeSelectorCompareSlice.actions.setSelectedGrade
          | typeof gradeSelectorCompareSlice.actions.overwriteSelectedGrade
          | typeof gradeSelectorCompareSlice.actions.removeSelectedGrades
          | typeof gradeSelectorFiltersSlice.actions.addFilterValue
          | typeof gradeSelectorFiltersSlice.actions.addColourFilterValue
          | typeof gradeSelectorFiltersSlice.actions.setFilterCategory
          | typeof gradeSelectorFiltersSlice.actions.clearFilterValue
          | typeof gradeSelectorFiltersSlice.actions.clearFilters
      >;

export type GradeSelectorDispatchType = Dispatch<GradeSelectorActionType>;

export type GradeSelectorLogicType<ActionType extends Action> = CreateLogicType<
    ActionType,
    GradeSelectorStateType,
    GradeSelectorDispatchType
>;

// Any because: gradeSelectorReducers contains reducers that can handle actions of any kind
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const gradeSelectorReducers: ReducersMapObject<GradeSelectorStateType, any> = {
    utils: UtilReducer,
    commonSettings: CommonSettingsReducer,
    modal: ModalReducer,
    gradeSelectorSettings: GradeSelectorSettingsReducer,
    [gradeSelectorLcvFiltersSlice.name]: gradeSelectorLcvFiltersSlice.reducer,
    [gradeSelectorFiltersSlice.name]: gradeSelectorFiltersSlice.reducer,
    [gradeSelectorModelSlice.name]: gradeSelectorModelSlice.reducer,
    [gradeSelectorFinanceSlice.name]: gradeSelectorFinanceSlice.reducer,
};

export const gradeSelectorServerStore = getInitToolkitStore(gradeSelectorReducers, [...gradeSelectorLcvFiltersLogic]);

export const gradeSelectorClientStore = getInitToolkitStore(gradeSelectorReducers, [...gradeSelectorLcvFiltersLogic]);
