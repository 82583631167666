import type {
    ApiBodyType,
    ApiColourType,
    ApiEngineTransmissionWheeldriveGradeBodyTypeSpecType,
    ApiEngineType,
    ApiEquipmentItemType,
    ApiEquipmentType,
    ApiFuelType,
    ApiGradeBodyEngineTransmissionWheeldriveCarType,
    ApiGradeType,
    ApiModelTypeWithoutError,
    ApiPriceType,
    ApiPromotionType,
    ApiSubmodelType,
    ApiTechSpecItemType,
    ApiTransmissionType,
    ApiWheelDriveType,
} from "../../../../../common-deprecated/types/ApiModel";

export enum Equipped {
    Standard = "s",
    Option = "o",
}

// Promotions
export type PromotionType = Pick<ApiPromotionType, "id"> & {
    name: string;
    disclaimer: string | null;
    index: number;
};

// Bodytypes
export type BodyTypeType = Pick<ApiBodyType, "id" | "name">;

// Wheeldrives
export type WheeldriveType = Pick<ApiWheelDriveType, "id" | "name" | "code">;

// Equipment
export type EquipmentGroupType = Pick<ApiEquipmentType, "category" | "path" | "root" | "default"> & {
    hasKeyFeatures: boolean;
};
export type EquipmentType = Pick<ApiEquipmentItemType, "id" | "name"> & {
    groupPath: string;
    isKeyFeature: boolean;
};

// Tech specs
export type TechSpecType = Pick<ApiTechSpecItemType, "id" | "name" | "unit" | "short" | "code" | "tooltip">;

// Fuels
export type FuelType = Pick<ApiFuelType, "id" | "name" | "hybrid" | "electric" | "fuelCell" | "code">;

// Transmissions
export type TransmissionType = Pick<ApiTransmissionType, "id" | "name" | "assets" | "code">;

// Engines
export type EngineType = Pick<ApiEngineType, "id" | "name" | "assets" | "plugin" | "code"> & {
    transmission: string;
    wheeldrive: string;
    wheeldriveVertification: number;
    grade: string;
    bodyType: string;
    fuel: FuelType;
    specs: Pick<ApiEngineTransmissionWheeldriveGradeBodyTypeSpecType, "value" | "numeric"> & {
        id: string;
    };
};

// Submodel
export type SubModelType = Pick<ApiSubmodelType, "id" | "name">;
// Colors
export type ColorType = Pick<ApiColourType, "id" | "name" | "rgb" | "asset" | "code" | "price" | "type">;

// Grades/BodyTypes
export type GradeType = Pick<ApiGradeType, "id" | "name" | "features"> & { intro: string };

export type CarType = Pick<
    ApiGradeBodyEngineTransmissionWheeldriveCarType,
    | "WebTitleSections"
    | "Country"
    | "Brand"
    | "Language"
    | "ModelID"
    | "CarID"
    | "ExteriorColourID"
    | "UpholsteryID"
    | "TotalPrice"
>;

export type EngineSpecType = Pick<TechSpecType, "id" | "name" | "unit" | "short" | "code" | "tooltip"> &
    Pick<ApiEngineTransmissionWheeldriveGradeBodyTypeSpecType, "value" | "numeric">;

export type GradeBodyTypeEngineTransmissionWheeldriveType = WheeldriveType & {
    specs: EngineSpecType[];
    car: CarType;
    eta?: string;
    price: ApiPriceType;
    colors: ColorType[];
    certification: number;
};

export type GradeBodyTypeEngineTransmissionType = TransmissionType & {
    wheeldrives: GradeBodyTypeEngineTransmissionWheeldriveType[];
};

export type GradeBodyTypeEngineType = Pick<EngineType, "id" | "name" | "fuel" | "plugin" | "code"> & {
    transmissions: GradeBodyTypeEngineTransmissionType[];
};

export type GradeBodyTypeEquipmentType = Omit<EquipmentType, "isStandardForAllGradeBodyTypes"> & {
    equipped: Equipped;
    gradeFeature?: boolean;
    gradeFeatureSortIndex: number;
    price?: ApiPriceType;
};

export type GradeBodyTypeType = {
    submodel?: SubModelType;
    grade: GradeType;
    bodyType: BodyTypeType;
    hasMultipleBodyTypes: boolean;
    engines: GradeBodyTypeEngineType[];
    equipment: GradeBodyTypeEquipmentType[];
    bestSellerLabel?: string;
    selectedColourId?: string;
    selectedMotorization: GradeBodySelectedMotorizationType;
};

export type GradeBodySelectedMotorizationType = {
    engineId: string;
    transmissionId: string;
    wheeldriveId: string;
};

export type ModelType = {
    id: ApiModelTypeWithoutError["id"];
    name: ApiModelTypeWithoutError["name"];
    code: ApiModelTypeWithoutError["code"];
    promotions: PromotionType[];
    //bodyTypes: BodyTypeType[];
    //wheeldrives: WheeldriveType[];
    equipmentGroups: EquipmentGroupType[];
    equipment: EquipmentType[];
    //techSpecGroups: TechSpecGroupType[];
    //techSpecs: TechSpecType[];
    //fuels: FuelType[];
    //transmissions: TransmissionType[];
    //engines: EngineType[];
    gradeBodyTypes: GradeBodyTypeType[];
    cashPriceDisclaimer: ApiModelTypeWithoutError["cashPriceDisclaimer"];
};
