import { trackEvent, updateTrackingBaseLayer } from "../../../../common-deprecated/utils/tracking";
import { EmissionSpecCode, FuelSpecCode, HydrogenSpecCode } from "../../../shared-logic/utils/constants/SpecConstants";
import type { GradeSelectorFiltersType } from "../redux/slices/GradeSelectorFiltersSlice";

const COMPONENT_NAME = "grade-selector-v3";
const COMPONENT_GROUP = "new-cars";
const COMPONENT_EVENT = "componentevent";
const CTA_EVENT = "ctaevent";

type TrackedFuelSpecCodes = HydrogenSpecCode | FuelSpecCode;

export const isTrackedFuelSpec = (specCode: string): specCode is TrackedFuelSpecCodes => {
    const normalizedCode = specCode.toLowerCase();
    return (
        Object.values(HydrogenSpecCode).includes(normalizedCode as HydrogenSpecCode) ||
        Object.values(FuelSpecCode).includes(normalizedCode as FuelSpecCode)
    );
};

export const isTrackedEmissionSpec = (specCode: string): specCode is TrackedFuelSpecCodes => {
    const normalizedCode = specCode.toLowerCase();
    return Object.values(EmissionSpecCode).includes(normalizedCode as EmissionSpecCode);
};

// Add commitHash to the base tracking layer so this will get added to all events
export const updateTrackingLayerVersioning = (): void => {
    updateTrackingBaseLayer({
        page: {
            ...((window.tngaDataLayer?.page as Record<string, unknown>) || {}),
            versions: {
                ...(window.tngaDataLayer?.page?.versions as Record<string, unknown>),
                "aph-webcarconfig": commithash,
            },
        },
    });
};

// When the content of a category is displayed (user click on category)
export const trackCategoryVisible = (state: GradeSelectorFiltersType, newCategory: string | undefined): void => {
    const root = newCategory?.split("/")[0];
    const subCategory = newCategory?.split("/")[1];
    const currentCategory = root ? state.category[root] : undefined;

    const isNewSubCategory = currentCategory?.subCategory !== (subCategory ?? newCategory);

    if (!currentCategory?.active || isNewSubCategory) {
        let trackChange;

        // If there is no root category, check if any filters are active.
        if (!root) {
            trackChange = Object.values(state.category).some((filter) => filter.active);
        }
        // If there is a subcategory or root category is not yet active.
        else {
            trackChange = !!subCategory || !!(!subCategory && !state.category[root]?.active);
        }

        if (trackChange) {
            trackEvent({
                name: COMPONENT_EVENT,
                component: COMPONENT_NAME,
                group: COMPONENT_GROUP,
                action: "select-category",
                label: typeof newCategory === "undefined" ? "Highlights" : newCategory,
            });
        }
    }
};

// User click on "browse" arrows/swipe to see other grades
export const trackBrowseGrades = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "browse-grade",
    });
};

// Click to expand engine configuration options
export const trackEngineOptionsOpen = (carGradeName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "expand-engine-options",
        label: carGradeName,
    });
};

// Click to reduce engine configuration options
export const trackEngineOptionsClose = (carGradeName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "reduce-engine-options",
        label: carGradeName,
    });
};

// When the content of fuel economy tooltip is displayed
export const trackFuelEconomyInfo = (engineID: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "view-fuel-economy-info",
        label: engineID,
    });
};

// When the content of gas emission tooltip is displayed
export const trackGasEmissionInfo = (engineID: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "view-emission-info",
        label: engineID,
    });
};

// Click on CTA to carconfigurator
// Click on configure CTA in compare
export const trackToCarConfigCTA = (carGradeName: string): void => {
    trackEvent({
        name: CTA_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: `click-cta-${carGradeName}`,
        label: "carconfigurator",
    });
};

// Click to select a motor in the list
export const trackSelectEngine = (carGradeName: string, engineID: string): void => {
    trackEvent({
        name: CTA_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "select-engine",
        label: `${carGradeName} - ${engineID}`,
    });
};

// Click on Compare button to open compare view
export const trackOpenCompare = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "open-compare",
    });
};

// Click on cross to close compare view
export const trackCloseCompare = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: "grade-compare-V3",
        group: COMPONENT_GROUP,
        action: "close-compare",
    });
};

// User click on button to reset filters
export const trackResetFilters = (): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "reset-filters",
    });
};

// Click to select colour
export const trackSelectColour = (colourName: string): void => {
    trackEvent({
        name: COMPONENT_EVENT,
        component: COMPONENT_NAME,
        group: COMPONENT_GROUP,
        action: "select-colour",
        label: colourName,
    });
};
